exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-private-yoga-retreats-js": () => import("./../../../src/pages/private-yoga-retreats.js" /* webpackChunkName: "component---src-pages-private-yoga-retreats-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-yoga-classes-js": () => import("./../../../src/pages/yoga-classes.js" /* webpackChunkName: "component---src-pages-yoga-classes-js" */),
  "component---src-pages-yoga-retreats-js": () => import("./../../../src/pages/yoga-retreats.js" /* webpackChunkName: "component---src-pages-yoga-retreats-js" */)
}

